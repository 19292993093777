// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---pages-404-tsx": () => import("./../../../pages/404.tsx" /* webpackChunkName: "component---pages-404-tsx" */),
  "component---pages-about-tsx": () => import("./../../../pages/about.tsx" /* webpackChunkName: "component---pages-about-tsx" */),
  "component---pages-help-tsx": () => import("./../../../pages/help.tsx" /* webpackChunkName: "component---pages-help-tsx" */),
  "component---pages-home-tsx": () => import("./../../../pages/home.tsx" /* webpackChunkName: "component---pages-home-tsx" */),
  "component---pages-index-tsx": () => import("./../../../pages/index.tsx" /* webpackChunkName: "component---pages-index-tsx" */),
  "component---pages-not-found-cover-tsx": () => import("./../../../pages/not-found-cover.tsx" /* webpackChunkName: "component---pages-not-found-cover-tsx" */),
  "component---pages-not-found-tsx": () => import("./../../../pages/not-found.tsx" /* webpackChunkName: "component---pages-not-found-tsx" */),
  "component---pages-privacy-tsx": () => import("./../../../pages/privacy.tsx" /* webpackChunkName: "component---pages-privacy-tsx" */),
  "component---pages-promo-tsx": () => import("./../../../pages/promo.tsx" /* webpackChunkName: "component---pages-promo-tsx" */),
  "component---pages-terms-tsx": () => import("./../../../pages/terms.tsx" /* webpackChunkName: "component---pages-terms-tsx" */)
}

